import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../components/complex/crud/TableAutoComponent";
import UiContext from "../../../store/ui-context";
import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../store/ui-slice";
import { Paginator } from "primereact/paginator";
import { format as dateFnsFormat, parse } from "date-fns";
import * as XLSX from "xlsx";

const title = "Customer Sales Report";

const tableConfigs = [
  {
    name: "No",
    field: "no",
    type: "number",
    align: "right",
    table: true,
    filter: false,
  },
  {
    name: "Customer",
    field: "customer",
    type: "text_plain",
    align: "left",
    table: true,
    filter: true,
  },
  {
    name: "Number of Visits",
    field: "visit",
    type: "number",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Total Amount Spent",
    field: "amount_spent",
    type: "number",
    align: "right",
    table: true,
    filter: true,
  },
  {
    name: "Average Amount Spent",
    field: "average_amount_spent",
    type: "number",
    align: "right",
    table: true,
    filter: true,
  },
];

const customColumns = [
  {
    field: "customer",
    component: (rowData) => {
      const code = rowData.customer_code;
      const name = rowData.customer_name;
      return (
        <div className="col-center-start">
          <span className="text-sm text-slate-400 font-semibold">{code}</span>
          <span>{name}</span>
        </div>
      );
    },
  },
  {
    field: "visit",
    component: (rowData) => {
      return <span>{rowData.visit.toLocaleString()}</span>;
    },
  },
  {
    field: "amount_spent",
    component: (rowData) => {
      return <span>{Math.floor(rowData.amount_spent).toLocaleString()}</span>;
    },
  },
  {
    field: "average_amount_spent",
    component: (rowData) => {
      return <span>{Math.floor(rowData.average_amount_spent).toLocaleString()}</span>;
    },
  }
];

const ReportCustomerSales = () => {
  const uiContext = useContext(UiContext);
  const toast = useRef();
  const dispatch = useDispatch();

  const [paging, setPaging] = useState({
    page: 1,
    take: 100000,
    filter: "",
    total: 0,
  });
  const [listData, setListData] = useState([]);

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1),
  );

  const [endDate, setEndDate] = useState(
    new Date(),
  );

  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  const generateFilter = () => {
    let filter = "";

    if (startDate) {
      if (filter.length > 0) filter += ";";

      let dateStart = startDate;
      let dateEnd = endDate;

      if (dateStart)
        filter += `transaction_date:>=:${dateFnsFormat(
          dateStart,
          "yyyy-MM-dd 00:00:00"
        )}`;

      if (dateEnd)
        filter += `;transaction_date:<=:${dateFnsFormat(
          dateEnd,
          "yyyy-MM-dd 23:59:59"
        )}`;
    }

    return filter;
  };

  const reloadData = async ({ page, take }) => {
    if (loading) return;
    setListData([]);
    const filter = generateFilter();

    const params = {
      prefix: "customer_sales_report",
      page: page,
      take: take,
      filter,
      order: "amount_spent",
      order_method: "DESC",
    };

    return requestGet({
      fullUrl: "api/report",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const newList = [];

        const page = pagination["page"];
        const take = pagination["take"];
        const startNumber = (page - 1) * take;

        data.forEach((item, index) => {
          newList.push({
            ...item,
            no: startNumber + index + 1,
          });
        });

        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
        });
        setListData(newList);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleReload = () => {
    reloadData({ page: 1, take: 100000 });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadData({ page: newPage, take: newTake });
  };

  const handleExportToExcel = (data, fileName) => {
    const headers = [
      { name: "Customer Code", key: "customer_code" },
      { name: "Customer Name", key: "customer_name" },
      { name: "Number of Visits", key: "visit" },
      { name: "Total Amount Spent", key: "amount_spent" },
      { name: "Average Amount Spent", key: "average_amount_spent" },
    ];

    const numericFields = ["visit", "amount_spent", "average_amount_spent"];

    const formattedData = data.map((row) => {
      const formattedRow = {};
      headers.forEach((header) => {
        if (numericFields.includes(header.key)) {
          formattedRow[header.name] = row[header.key]
              ? Number(row[header.key])
              : 0;
        } else {
          formattedRow[header.name] = row[header.key];
        }
      });
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    XLSX.utils.sheet_add_aoa(worksheet, [headers.map((header) => header.name)], {
      origin: "A1",
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-start-start gap-2">
          <Calendar
            id="startDate"
            value={startDate}
            showButtonBar
            showIcon
            onChange={(e) => {
              setStartDate(e.value);
            }}
            className={`dateInput w-[17rem]`}
            locale="id"
          />
          <Calendar
            id="endDate"
            value={endDate}
            showButtonBar
            showIcon
            onChange={(e) => {
              setEndDate(e.value);
            }}
            className={`dateInput w-[17rem]`}
            locale="id"
          />
        </div>
        <div className="row-between-center"></div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            // disabled
            onClick={() => handleExportToExcel(listData, "Customer Sales Report")}
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <div className="w-100" id="table-to-pdf">
            <TableAutoComponent
              onSelectionChange={() => {}}
              configs={tableConfigs}
              loading={loading}
              value={listData}
              onEditRow={async (row) => {}}
              onDeleteRow={(row) => {}}
              customColumns={customColumns}
            />
          </div>
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50, 100, 100000]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default ReportCustomerSales;
