import React, { useContext, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { format as dateFnsFormat, parse } from "date-fns";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import UiContext from "../store/ui-context";
import useAPIRequest from "../custom_hooks/simple/useAPIRequest";
import calculateUnpayedAmount from "../utils/jelim/CalculateUnpayedAmount";
import { Divider } from "primereact/divider";
// import { process.env.REACT_APP_PRINTER__HOST_URL } from "../configs/constants";

const title = "Payment Receipt";

const PrintSalesReceiptDialog = (props) => {
  const { onConfirm, data, change } = props;
  const uiContext = useContext(UiContext);

  useEffect(() => {
    if (props.visible) {
    }
  }, [props.visible]);

  const print = async () => {
    const printData = generatePrintData();
    await sendPrintRequest(printData);
  };

  const generatePrintData = () => {
    const lines = [];

    // header
    lines.push({ text: "JELIM", font: 2, align: "center" });
    lines.push({ text: "Korean Aesthetic & Beauty Center", align: "center" });
    lines.push({
      text: "Jl. Brid. Katamso Komp. Centrium No. 9",
      align: "center",
    });
    lines.push({ text: "Phone. (061) 42001777", align: "center" });
    lines.push({ text: "LINE" });

    // customer data
    if (data.data_customer) {
      const customer = data.data_customer;
      lines.push({
        text: `(${customer.code ?? ""})`,
        font: 2,
        align: "center",
      });
      lines.push({
        text: `${customer.name ?? ""}`,
        font: 2,
        align: "center",
      });
      lines.push({
        text: `Point: ${(customer.point ?? 0).toLocaleString("id-ID")}`,
        font: 2,
        align: "center",
      });
      lines.push({ text: "LINE" });
    }

    // invoice data
    lines.push({
      text_1: `# ${data.number}`,
      text_2: `${dateFnsFormat(
        new Date(data.transaction_date),
        "dd/MM/yyyy HH:mm"
      )}`,
    });
    lines.push({ text: "LINE" });

    // sales items
    if (data.data_sales_items) {
      data.data_sales_items.forEach((sales_item) => {
        const {
          id,
          data_product,
          price,
          conversion,
          quantity,
          grand_total,
          sub_total,
          discount_total,
          discount_value,
          discount_name,
        } = sales_item;
        const { code, name } = data_product;
        lines.push({ text: name });
        lines.push({
          text_1: `  ${quantity.toLocaleString(
            "id-ID"
          )} x @ ${price.toLocaleString("id-ID")}`,
          text_2: sub_total.toLocaleString("id-ID"),
        });

        if (discount_total != 0 && discount_value != 0 && discount_name != "") {
          lines.push({
            text_1: `  ${discount_name}`,
            text_2: `-${discount_total.toLocaleString("id-ID")}`,
          });
        }
      });

      lines.push({ text: "LINE" });
    }

    // total summary
    const {
      sub_total,
      grand_total,
      discount_total,
      data_sales_payments,
      data_sales_discounts,
    } = data;

    lines.push({
      text_1: "Subtotal",
      text_2: sub_total.toLocaleString("id-ID"),
    });

    for (const salesDiscount of data_sales_discounts) {
      lines.push({
        text_1: `${salesDiscount.name}`,
        text_2: `-${salesDiscount.total.toLocaleString("id-ID")}`,
      });
    }

    lines.push({
      text: "",
    });
    lines.push({
      text_1: "TOTAL",
      text_2: grand_total.toLocaleString("id-ID"),
      font: 2,
    });

    // payment section
    if (data_sales_payments) {
      data_sales_payments.forEach((sales_payments) => {
        const { data_payment, amount } = sales_payments;
        const { code, name } = data_payment;
        lines.push({
          text_1: `  ${name}`,
          text_2: amount.toLocaleString("id-ID"),
        });
      });
    }

    lines.push({
      text: "",
    });

    let changeBack = 0;
    if (data_sales_payments) {
      const totalChangeBack = data_sales_payments.reduce(
        (acc, curr) => acc + curr.change_back,
        0
      );
      changeBack = totalChangeBack;
    }

    if (changeBack > 0) {
      lines.push({
        text_1: "Change",
        text_2: changeBack.toLocaleString("id-ID"),
      });
    }

    if (data.notes) {
      lines.push({ text: "LINE" });

      lines.push({
        text: `Note: ${data.notes}`,
      });
    }

    lines.push({ text: "LINE" });

    // footer
    lines.push({
      text: "=== Thank You & Come Again ===",
      align: "center",
    });

    return lines;
  };

  const sendPrintRequest = async (lines) => {
    const response = await fetch(
      `${process.env.REACT_APP_PRINTER__HOST_URL}/api/print`,
      {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: lines }),
      }
    );
    return response.json();
  };

  const handlePrint = (event) => {
    event.preventDefault();
    print();
  };

  const dialogFooter = (
    <div className="row-between-center w-100">
      <Button
        className="w-full"
        label="Close"
        icon="pi pi-arrow-left"
        onClick={(e) => {
          e.preventDefault();
          props.onHide();
        }}
      />
      <Button
        className="w-full"
        severity="success"
        label="Print"
        icon="pi pi-print"
        onClick={handlePrint}
      />
    </div>
  );

  const CompanyHeaderSection = () => {
    return (
      <div className="col-start-center w-full">
        <span className="font-semibold text-lg">JELIM</span>
        <span>Korean Aesthetic & Beauty Center</span>
        <span>Jl. Brid. Katamso Komp. Centrium No. 9</span>
        <span>Phone. (061) 42001777</span>
      </div>
    );
  };

  const CustomerSection = () => {
    if (data && data.data_customer) {
      const { code, point, name } = data.data_customer;
      return (
        <div className="col-between-center w-full">
          <span className="font-semibold">{`(${code})`}</span>
          <span className="font-semibold">{`${name}`}</span>
          <span>Point : {point.toLocaleString("id-ID")}</span>
        </div>
      );
    } else {
      return (
        <div className="col-start-center w-full">
          <span>-</span>
        </div>
      );
    }
  };

  const SalesSection = () => {
    const { transaction_date, number } = data;
    if (number && transaction_date) {
      return (
        <div className="row-between-center w-full">
          <span># {number}</span>
          <span>
            {dateFnsFormat(new Date(transaction_date), "dd/MM/yyyy HH:mm")}
          </span>
        </div>
      );
    } else {
      return (
        <div className="row-between-center w-full">
          <span>-</span>
          <span>-</span>
        </div>
      );
    }
  };

  const SalesDetailSection = () => {
    return (
      <div className="col-start-center w-full gap-4">
        {data &&
          data.data_sales_items &&
          data.data_sales_items.map((sales_item) => {
            const {
              id,
              data_product,
              price,
              conversion,
              quantity,
              grand_total,
              sub_total,
              discount_total,
              discount_value,
              discount_name,
            } = sales_item;
            const { code, name } = data_product;
            return (
              <div key={id} className="col-start-start w-full">
                <span className="font-semibold">{name}</span>
                <div className="row-between-center w-full pl-4">
                  <span>
                    {quantity.toLocaleString("id-ID")} x @
                    {" " + price.toLocaleString("id-ID")}
                  </span>
                  <span>{sub_total.toLocaleString("id-ID")}</span>
                </div>

                {discount_total != 0 &&
                  discount_value != 0 &&
                  discount_name != "" && (
                    <div className="row-between-center w-full pl-4">
                      <span>{discount_name}</span>
                      <span>-{discount_total.toLocaleString("id-ID")}</span>
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    );
  };

  const SalesSummarySection = () => {
    const {
      sub_total,
      grand_total,
      discount_total,
      data_sales_payments,
      data_sales_discounts,
    } = data;

    let changeBack = 0;
    if (data_sales_payments) {
      const totalChangeBack = data_sales_payments.reduce(
        (acc, curr) => acc + curr.change_back,
        0
      );
      changeBack = totalChangeBack;
    }

    return (
      <div className="col-start-center w-full">
        <div className="row-between-center w-full">
          <span className="font-semibold">Subtotal</span>
          <span className="font-semibold">
            {sub_total && sub_total.toLocaleString("id-ID")}
          </span>
        </div>

        {data_sales_discounts &&
          data_sales_discounts.map((discount) => {
            return (
              <div key={discount.id} className="row-between-center w-full">
                <span className="">{discount.name}</span>
                <span className="">
                  -{discount.total.toLocaleString("id-ID")}
                </span>
              </div>
            );
          })}

        <div className="row-between-center w-full py-2">
          <span className="font-semibold">Grandtotal</span>
          <span className="font-semibold">
            {grand_total && grand_total.toLocaleString("id-ID")}
          </span>
        </div>

        {/* payment section */}
        {data_sales_payments && (
          <div className="col-start-center w-full pl-4">
            {data_sales_payments.map((sales_payments) => {
              const { data_payment, amount } = sales_payments;
              const { code, name } = data_payment;
              return (
                <div
                  key={sales_payments.id}
                  className="row-between-center w-full"
                >
                  <span>{name}</span>
                  <span>{amount.toLocaleString("id-ID")}</span>
                </div>
              );
            })}
          </div>
        )}

        {changeBack > 0 && (
          <div className="row-between-center w-full pt-3">
            <span className="">Change Back</span>
            <span className="">
              {changeBack && changeBack.toLocaleString("id-ID")}
            </span>
          </div>
        )}
      </div>
    );
  };

  const NotesSection = () => {
    if (data.notes) {
      const result = [];
      result.push(
        <div key="notes" className="row-start-center gap-2 w-full">
          <span className="font-semibold">Note: </span>
          <span>{data.notes}</span>
        </div>
      );
      result.push(<Divider key="notes-divider" className="w-full !my-0" />);
      return result;
    } else {
      return <> </>;
    }
  };

  const FooterSection = () => {
    return (
      <div className="col-start-center w-full pb-10">
        <span>=== Thank You & Come Again ===</span>
      </div>
    );
  };

  return (
    <Dialog
      visible={props.visible}
      header={title}
      modal
      className={`p-fluid w-[26rem] min-h-[33rem]`}
      footer={dialogFooter}
      onHide={props.onHide}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <div className="col-start-center w-full gap-3">
        <CompanyHeaderSection />
        <Divider className="w-full !my-0" />
        <CustomerSection />
        <Divider className="w-full !my-0" />
        <SalesSection />
        <Divider className="w-full !my-0" />
        <SalesDetailSection />
        <Divider className="w-full !my-0" />
        <SalesSummarySection />
        <Divider className="w-full !my-0" />
        <NotesSection />
        <FooterSection />
      </div>
    </Dialog>
  );
};

export default PrintSalesReceiptDialog;
