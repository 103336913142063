import { useContext, useEffect, useState } from "react";
import useAPIRequest from "../../../../custom_hooks/simple/useAPIRequest";
import generateRandomMinusNumber from "../../../../utils/GenerateRandomMinusNumber";
import calculateItemDiscount from "../../../../utils/jelim/CalculateItemDiscount";
import calculateSubtotalDiscount from "../../../../utils/jelim/CalculateSubtotalDiscount";
import { format as dateFnsFormat, format, parse } from "date-fns";
import { useSelector } from "react-redux";
import UiContext from "../../../../store/ui-context";
import calculateUnpayedAmount from "../../../../utils/jelim/CalculateUnpayedAmount";

const useSalesPage = (props) => {
  const uiContext = useContext(UiContext);

  const [outstandingInvoices, setOutstandingInvoices] = useState([]);
  const { requestPost, requestDelete, requestGetOne, loading, requestGet } =
    useAPIRequest();
  const { loading: loadingOutstanding, requestGet: requestGetOutstanding } =
    useAPIRequest();
  const { loading: loadingProduct, requestGet: requestGetProduct } =
    useAPIRequest();
  const [displays, setDisplays] = useState([]);
  const [sales, setSales] = useState({});
  const [products, setProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getOutstandingInvoice({});
    getDisplay({});
  }, []);

  const getSales = async ({ id }) => {
    await requestGetOne({
      fullUrl: `/api/sales/sales/one/${id}`,

      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].no = i + 1;
          }
          setSales(data);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const reloadCustomer = async () => {
    if (sales.data_customer) {
      await requestGetOne({
        fullUrl: `/api/customer/customer/one/${sales.data_customer.id}`,

        onSuccess: ({ pagination, message, data }) => {
          if (data) {
            setSales({ ...sales, data_customer: data });
          }
        },
        onError: ({ message, data }) =>
          uiContext.showErrorMsg("Error", message),
      });
    }
  };

  const saveSalesPayment = async ({ salesPayment }) => {
    return await requestPost({
      fullUrl: "api/sales/salespayment/save",
      body: { ...salesPayment, sales_id: sales.id },
      onSuccess: ({ data }) => {},
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const deleteSalesPayment = async ({ salesPayment }) => {
    return await requestDelete({
      fullUrl: "api/sales/salespayment/delete",
      ids: [salesPayment.id],
      onSuccess: ({ data }) => {},
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const saveSalesDiscount = async ({ salesDiscount }) => {
    return await requestPost({
      fullUrl: "api/sales/salesdiscount/save",
      body: { ...salesDiscount, sales_id: sales.id },
      onSuccess: ({ data }) => {},
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const deleteSalesDiscount = async ({ salesDiscount }) => {
    return await requestDelete({
      fullUrl: "api/sales/salesdiscount/delete",
      ids: [salesDiscount.id],
      onSuccess: ({ data }) => {},
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const saveSalesItem = async ({ salesItem }) => {
    return await requestPost({
      fullUrl: "api/sales/salesitem/save",
      body: { ...salesItem, sales_id: sales.id },
      onSuccess: ({ data }) => {},
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const addSalesNote = async ({ note }) => {
    return await requestPost({
      fullUrl: "api/sales/sales/addnote",
      body: { id: sales.id, notes: note },
      onSuccess: ({ data }) => {},
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const deleteSalesItem = async ({ salesItem }) => {
    return await requestDelete({
      fullUrl: "api/sales/salesitem/delete",
      ids: [salesItem.id],
      onSuccess: ({ data }) => {},
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const saveSales = async ({ sales }) => {
    return await requestPost({
      fullUrl: "api/sales/sales/save",
      body: { ...sales },
      onSuccess: ({ data }) => {
        if (data) {
          let i = 0;
          for (i = 0; i < outstandingInvoices.length; i++) {
            const outstandingInvoice = outstandingInvoices[i];
            if (outstandingInvoice.id === data.id) {
              const newOutstandingInvoices = [...outstandingInvoices];
              newOutstandingInvoices[i] = data;
              setOutstandingInvoices(newOutstandingInvoices);
              break;
            }
          }
          if (i >= outstandingInvoices.length) {
            const newOutstandingInvoices = [...outstandingInvoices];
            newOutstandingInvoices.push(data);
            setOutstandingInvoices(newOutstandingInvoices);
          }
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const deleteSales = async ({ sales, voidReason }) => {
    const body = {
      id: sales.id,
      voided_date: format(new Date(), "yyyy-MM-dd HH:mm"),
      voided_reason: voidReason,
    };
    return await requestPost({
      fullUrl: "api/sales/sales/void",
      body,
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
      onSuccess: ({ data }) => {},
    });
    // return await requestDelete({
    //   fullUrl: "api/sales/sales/delete",
    //   ids: [sales.id],
    //   onSuccess: ({ data }) => {},
    //   onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    // });
  };

  const getOutstandingInvoice = async () => {
    setOutstandingInvoices([]);
    const filter = `payed:=:0;voided:=:0`;
    await requestGetOutstanding({
      fullUrl: "api/sales/sales/data",
      params: {
        page: 1,
        take: 10,
        filter: filter,
        order: "id",
        order_method: "ASC",
        with_branch: 1,
        with_customer: 1,
        with_cashier: 1,
        with_bc: 1,
        with_sales_items: 1,
        with_crm_schedule_customer: 1,
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].no = i + 1;
          }
          setOutstandingInvoices(data);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const getDisplay = async ({}) => {
    await requestGet({
      fullUrl: "api/masterdata/productdisplay/data",
      params: {
        page: 1,
        take: 1000,
        order: "id",
        order_method: "ASC",
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].no = i + 1;
          }
          setDisplays(data);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const getProduct = async ({ id, filterText }) => {
    let filter = ``;

    if (id) {
      filter = `product_display_id:=:${id}`;
    } else {
      filter = `name:contains:%${filterText.replaceAll(" ", "%")}%`;
    }
    filter += `;is_active:=:1`;
    await requestGetProduct({
      fullUrl: "api/masterdata/product/data",
      params: {
        page: 1,
        take: 1000,
        filter: filter,
        order: "id",
        order_method: "ASC",
      },
      onSuccess: ({ pagination, message, data }) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].no = i + 1;
          }
          setProducts(data);
        }
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const reloadListProduct = (display) => {
    getProduct(display);
  };

  const reloadListPenjualan = (sales) => {
    getSales(sales);
  };

  const newSalesProcedure = () => {
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    // const day = String(currentDate.getDate()).padStart(2, "0");
    // const hours = String(currentDate.getHours()).padStart(2, "0");
    // const minutes = String(currentDate.getMinutes()).padStart(2, "0");

    // const invoiceNumber = `${year}${month}${day}${hours}${minutes}`;

    const newSales = {
      transaction_date: dateFnsFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      // number: invoiceNumber,
      //perbaikan di sini
      cashier_id: user.employee_id, //ini
      completed: null,
      branch_id: 1,
      // bc_id: user.id,
    };

    setSales(newSales);

    return newSales;
  };

  const saveAndChangeToNewCustomer = async (customer) => {
    return await requestPost({
      fullUrl: "api/customer/customer/save",
      body: { ...customer, branch_id: 1 },
      onSuccess: ({ data }) => {
        changeCustomer(data);
      },
      onError: ({ message, data }) => uiContext.showErrorMsg("Error", message),
    });
  };

  const scanCustomerForNewSales = async (barcode) => {
    const customerCode = barcode.trim().toUpperCase().replaceAll(" ", "");
    const customerCodeWSpace = customerCode.replaceAll("JL", "JL ");

    return await requestGet({
      fullUrl: `api/customer/customer/data`,
      params: {
        filter: `code:=:${customerCode}||code:=:${customerCodeWSpace}`,
        with_bc: 1,
      },
      onSuccess: ({ message, data }) => {
        if (data && data.length > 0) {
          const customer = data[0];

          changeCustomer(customer, true);
        }
      },
      onError: ({ message, data }) => {},
    });
  };

  const changeCustomer = async (customer, inputByScan) => {
    let newSales = { ...sales };
    if (!sales || Object.keys(sales).length === 0) {
      newSales = newSalesProcedure();
    }

    if (
      newSales.data_crm_schedule_customer &&
      newSales.data_crm_schedule_customer.customer_id != customer.id
    ) {
      newSales.crm_schedule_customer_id = null;
      newSales.data_crm_schedule_customer = null;
    }

    if (customer) {
      const newCustomerId = customer ? customer.id : null;

      // check if there is an appointment for this customer
      let appointment;
      const appointmentAvailable = await requestGet({
        fullUrl: `api/crm/crmschedulecustomer/data`,
        params: {
          filter: `customer_id:=:${newCustomerId};transaction_date:>=:${dateFnsFormat(
            new Date(),
            "yyyy-MM-dd"
          )} 00:00:00;transaction_date:<=:${dateFnsFormat(
            new Date(),
            "yyyy-MM-dd"
          )} 23:59:59`,
        },
        onSuccess: ({ message, data }) => {
          if (data && data.length > 0) {
            appointment = data[0];
          }
        },
        onError: ({ message, data }) => {},
      });

      newSales = {
        ...newSales,
        data_customer: customer,
        customer_id: newCustomerId,
        bc_id: customer.bc_id,
      };

      if (appointment) {
        newSales.crm_schedule_customer_id = appointment.id;
        newSales.data_crm_schedule_customer = appointment;
      }

      if (inputByScan) {
        newSales.check_in = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      }

      const savedSales = await saveSales({ sales: newSales });
      if (saveSales) {
        setSales(savedSales);
      }
    }
  };

  // const deleteSalesItem = (salesItemId) => {
  //   let salesTmp = { ...sales };
  //   let oldSalesItems = salesTmp.data_sales_items;
  //   let newSalesItems = [];
  //   if (salesItemId) {
  //     newSalesItems = oldSalesItems.filter((item) => item.id != salesItemId);
  //   }
  //   salesTmp.data_sales_items = newSalesItems;
  //   updateTotalValue(salesTmp);
  //   setSales(salesTmp);
  // };

  const addSalesItemByProduct = async (product) => {
    if (product) {
      let salesTmp = { ...sales };
      let newSalesItems = [];
      if (salesTmp.data_sales_items) {
        newSalesItems = salesTmp.data_sales_items;
      }

      // create new sales item object
      const newSalesItem = {
        product_id: product.id,
        branch_id: null,
        doctor_id: null,
        therapist_1_id: null,
        therapist_2_id: null,
        therapist_3_id: null,
        nurse_1_id: null,
        nurse_2_id: null,
        nurse_3_id: null,
        quantity: 1,
        price: product.price,
        conversion: 1,
        unit: "unit",
        sub_total: 1 * product.price,
        discount_type: 1,
        discount_value: 0,
        distcount_total: 0,
        grand_total: 1 * product.price,
        order_index: 1,
        data_product: product,
      };

      const savedSalesItem = await saveSalesItem({ salesItem: newSalesItem });
      if (savedSalesItem) {
        newSalesItems.push(savedSalesItem);
        salesTmp.data_sales_items = newSalesItems;

        updateTotalValue(salesTmp);
        setSales(salesTmp);
      }
    }
  };

  const scanCheckIn = async (barcode) => {
    const customerCode = barcode.trim().toUpperCase().replaceAll(" ", "");
    const customerCodeWSpace = customerCode.replaceAll("JL", "JL ");

    if (sales && sales.data_customer) {
      const customer = sales.data_customer;
      if (
        customerCode === customer.code ||
        customerCodeWSpace === customer.code
      ) {
        return await requestPost({
          fullUrl: "api/sales/sales/checkin",
          body: { id: sales.id },
          onSuccess: ({ data }) => {
            setSales({ ...sales, check_in: data.check_in });
          },
          onError: ({ message, data }) =>
            uiContext.showErrorMsg("Error", message),
        });
      } else {
        uiContext.showErrorMsg("Error", "Customer code not match");
        return false;
      }
    }

    return false;
  };

  const scanCheckOut = async (barcode) => {
    const customerCode = barcode.trim().toUpperCase().replaceAll(" ", "");
    const customerCodeWSpace = customerCode.replaceAll("JL", "JL ");

    const byPass = barcode === "**FORCE-SIGN-OUT**";

    if (sales && sales.data_customer) {
      const customer = sales.data_customer;
      if (
        byPass ||
        customerCode === customer.code ||
        customerCodeWSpace === customer.code
      ) {
        if (!sales.check_in) {
          uiContext.showErrorMsg("Error", "Customer must check in first");
          return false;
        }

        return await requestPost({
          fullUrl: "api/sales/sales/checkout",
          body: { id: sales.id },
          onSuccess: ({ data }) => {
            setSales({ ...sales, check_out: data.check_out });
          },
          onError: ({ message, data }) =>
            uiContext.showErrorMsg("Error", message),
        });
      } else {
        uiContext.showErrorMsg("Error", "Customer code not match");
        return false;
      }
    }

    return false;
  };

  const removeSalesItem = async (salesItem) => {
    if (salesItem) {
      const ids = await deleteSalesItem({ salesItem });
      if (ids != null) {
        const salesItemId = salesItem.id;
        let salesTmp = { ...sales };
        let oldSalesItems = salesTmp.data_sales_items;
        let newSalesItems = [];
        if (salesItemId) {
          newSalesItems = oldSalesItems.filter(
            (item) => item.id != salesItemId
          );
        }
        salesTmp.data_sales_items = newSalesItems;
        updateTotalValue(salesTmp);
        setSales(salesTmp);
      }
    }
  };

  const setSalesItem = ({ sales, salesItem }) => {
    if (saveSalesItem) {
      const salesTmp = { ...sales };
      for (let i = 0; i < sales.data_sales_items.length; i++) {
        if (sales.data_sales_items[i].id === salesItem.id) {
          sales.data_sales_items[i] = salesItem;
          break;
        }
      }
      updateTotalValue(salesTmp);
      setSales(salesTmp);
    }
  };

  const changeSalesItemQuantity = async ({ quantity, salesItem }) => {
    const newSalesItem = { ...salesItem, quantity: quantity };
    const savedSalesItem = await saveSalesItem({ salesItem: newSalesItem });
    setSalesItem({ sales, salesItem: savedSalesItem });
  };

  const changeSalesItemDiscount = async ({ discount, salesItem }) => {
    let discount_type = 1;
    let discount_value = 0;
    let discount_total = 0;
    let discount_name = "";

    if (discount && discount.length > 0 && salesItem) {
      const discountSingle = discount[0];
      discount_type = discountSingle.discount_type;
      discount_value = discountSingle.value;
      discount_name = discountSingle.name;
      discount_total = 0;
    }

    const newSalesItem = {
      ...salesItem,
      discount_type: discount_type,
      discount_value: discount_value,
      discount_name: discount_name,
      distcount_total: discount_total,
    };
    const savedSalesItem = await saveSalesItem({ salesItem: newSalesItem });
    setSalesItem({ sales, salesItem: savedSalesItem });
  };

  const changeSalesItemDoctor = async ({ doctor, salesItem }) => {
    if (doctor && doctor.length > 0 && salesItem) {
      const doctorSingle = doctor[0];
      const newSalesItem = {
        ...salesItem,
        doctor_id: doctorSingle.id,
        data_doctor: doctorSingle,
      };
      const savedSalesItem = await saveSalesItem({ salesItem: newSalesItem });
      setSalesItem({ sales, salesItem: savedSalesItem });
    }
  };

  const changeSalesItemNurse = async ({ nurse, salesItem }) => {
    if (nurse && nurse.length > 0 && salesItem) {
      let nurse_1_id = null,
        nurse_2_id = null,
        nurse_3_id = null;

      if (nurse.length >= 1) {
        nurse_1_id = nurse[0].id;
      }
      if (nurse.length >= 2) {
        nurse_2_id = nurse[1].id;
      }
      if (nurse.length >= 3) {
        nurse_3_id = nurse[2].id;
      }
      const newSalesItem = {
        ...salesItem,
        nurse_1_id,
        nurse_2_id,
        nurse_3_id,
        data_nurse_1: nurse.length >= 1 ? nurse[0] : null,
        data_nurse_2: nurse.length >= 2 ? nurse[1] : null,
        data_nurse_3: nurse.length >= 3 ? nurse[2] : null,
      };

      const savedSalesItem = await saveSalesItem({ salesItem: newSalesItem });
      setSalesItem({ sales, salesItem: savedSalesItem });
    }
  };

  const changeSalesItemTherapist = async ({ therapist, salesItem }) => {
    if (therapist && therapist.length > 0 && salesItem) {
      let therapist_1_id = null,
        therapist_2_id = null,
        therapist_3_id = null;

      if (therapist.length >= 1) {
        therapist_1_id = therapist[0].id;
      }
      if (therapist.length >= 2) {
        therapist_2_id = therapist[1].id;
      }
      if (therapist.length >= 3) {
        therapist_3_id = therapist[2].id;
      }
      const newSalesItem = {
        ...salesItem,
        therapist_1_id,
        therapist_2_id,
        therapist_3_id,
        data_therapist_1: therapist.length >= 1 ? therapist[0] : null,
        data_therapist_2: therapist.length >= 2 ? therapist[1] : null,
        data_therapist_3: therapist.length >= 3 ? therapist[2] : null,
      };

      const savedSalesItem = await saveSalesItem({ salesItem: newSalesItem });
      setSalesItem({ sales, salesItem: savedSalesItem });
    }
  };

  const addPayment = async ({ payment, amount }) => {
    const newSales = { ...sales };
    const unpayed = calculateUnpayedAmount(newSales);

    const newPayment = {
      payment_id: payment.id,
      transaction_date: dateFnsFormat(new Date(), "yyyy-MM-dd HH:mm:ss"),
      amount: amount > unpayed ? unpayed : amount,
      description: null,
    };

    const changeBack = amount - newPayment.amount;
    if (changeBack >= 0) {
      newPayment["change_back"] = changeBack;
    }

    const savedSalesPayment = await saveSalesPayment({
      salesPayment: newPayment,
    });

    if (savedSalesPayment) {
      if (!sales.data_sales_payments) {
        newSales.data_sales_payments = [];
      }

      newSales.data_sales_payments.push(savedSalesPayment);
      updateTotalValue(newSales);
      setSales(newSales);

      const newUnpayed = calculateUnpayedAmount(newSales);
      if (newUnpayed <= 0) {
        const change = amount - unpayed;
        return change;
      }

      return -1;
    } else {
      return -1;
    }
  };

  const removePayment = async ({ salesPayment }) => {
    if (salesPayment) {
      const ids = await deleteSalesPayment({ salesPayment });
      if (ids != null) {
        const salesPaymentId = salesPayment.id;
        let salesTmp = { ...sales };
        let oldSalesPayments = salesTmp.data_sales_payments;
        let newSalesPayments = [];
        if (salesPaymentId) {
          newSalesPayments = oldSalesPayments.filter(
            (item) => item.id != salesPaymentId
          );
        }
        salesTmp.data_sales_payments = newSalesPayments;
        updateTotalValue(salesTmp);
        setSales(salesTmp);
      }
    }
  };

  const removeDiscount = async ({ salesDiscount }) => {
    if (salesDiscount) {
      const ids = await deleteSalesDiscount({ salesDiscount });
      if (ids != null) {
        const salesDiscountId = salesDiscount.id;
        let salesTmp = { ...sales };
        let oldSalesDiscounts = salesTmp.data_sales_discounts;
        let newSalesDiscounts = [];
        if (salesDiscountId) {
          newSalesDiscounts = oldSalesDiscounts.filter(
            (item) => item.id != salesDiscountId
          );
        }
        salesTmp.data_sales_discounts = newSalesDiscounts;
        updateTotalValue(salesTmp);
        setSales(salesTmp);
      }
    }
  };

  const updateTotalValue = (sales) => {
    let newSubTotal = 0;
    if (sales.data_sales_items) {
      for (let i = 0; i < sales.data_sales_items.length; i++) {
        const dataSalesItem = sales.data_sales_items[i];
        const { quantity, price, discount_type, discount_value } =
          dataSalesItem;
        const itemSubtotal = quantity * price;
        const itemDiscount = calculateItemDiscount({
          quantity,
          subtotal: itemSubtotal,
          type: discount_type,
          value: discount_value,
        });
        const itemGrandtotal = itemSubtotal - itemDiscount;
        dataSalesItem.sub_total = itemGrandtotal;
        newSubTotal += itemGrandtotal;
      }
    }
    sales.sub_total = newSubTotal;
    let totalDiscount = 0;
    // console.log("DATA SALES DISCOUNT", sales.data_sales_discounts);
    for (const subtotalDiscount of sales.data_sales_discounts) {
      const discount = calculateSubtotalDiscount({
        type: subtotalDiscount.type,
        value: subtotalDiscount.value,
        subtotal: newSubTotal,
      });
      // console.log("DISCOUNT", discount);
      totalDiscount += discount;
    }
    sales.discount_value = totalDiscount;
    sales.grand_total = newSubTotal - totalDiscount;
  };

  const addSalesDiscount = async ({ discount }) => {
    if (discount && discount.length > 0) {
      const discountSingle = discount[0];

      const discountBody = {
        sales_id: sales.id,
        type: discountSingle.discount_type,
        value: discountSingle.value,
        name: discountSingle.name,
      };

      const savedSalesDiscount = await saveSalesDiscount({
        salesDiscount: discountBody,
      });
      const newSales = { ...sales };

      newSales.data_sales_discounts.push(savedSalesDiscount);
      updateTotalValue(newSales);
      setSales(newSales);

      // const savedSales = await saveSales({ sales: newSales });
      // if (saveSales) {
      //   setSales(savedSales);
      // }
    }
  };

  // const changeSalesDiscount = async ({ discount }) => {
  //   if (discount && discount.length > 0) {
  //     const discountSingle = discount[0];

  //     const newSales = {
  //       ...sales,
  //       discount_type: discountSingle.discount_type,
  //       discount_value: discountSingle.value,
  //     };

  //     const savedSales = await saveSales({ sales: newSales });
  //     if (saveSales) {
  //       setSales(savedSales);
  //     }
  //   }
  // };

  const voidSales = async (voidReason) => {
    if (sales) {
      const ids = await deleteSales({ sales, voidReason });
      if (ids != null) {
        getOutstandingInvoice();
        newSalesProcedure();
        return true;
      }
    }
    return false;
  };

  const onChangeNote = async ({ note }) => {
    // console.log("NOTES => " + note);
    let newSales = { ...sales };
    if (!sales || Object.keys(sales).length === 0) {
      newSales = newSalesProcedure();
    }
    const savedSales = await addSalesNote({ note });
    newSales = { ...sales, notes: savedSales.notes };
    if (savedSales) {
      setSales(newSales);
    }

    // const savedSales = await saveSales({ sales: newSales });
    // if (savedSales) {
    //   setSales(savedSales);
    // }
  };

  const addSalesItemByCustomerPackage = async ({ customerFreeProducts }) => {
    if (customerFreeProducts && customerFreeProducts.length > 0) {
      for (const customerFreeProduct of customerFreeProducts) {
        let salesTmp = { ...sales };
        let newSalesItems = [];
        if (salesTmp.data_sales_items) {
          newSalesItems = salesTmp.data_sales_items;
        }
        // create new sales item object
        const newSalesItem = {
          product_id: customerFreeProduct.product_id,
          branch_id: null,
          doctor_id: null,
          therapist_1_id: null,
          therapist_2_id: null,
          therapist_3_id: null,
          nurse_1_id: null,
          nurse_2_id: null,
          nurse_3_id: null,
          customer_free_product_id: customerFreeProduct.id,
          quantity: customerFreeProduct.quantity,
          price: 0,
          conversion: 1,
          unit: "unit",
          sub_total: 0,
          discount_type: 1,
          discount_value: 0,
          distcount_total: 0,
          grand_total: 0,
          order_index: 1,
          data_product: customerFreeProduct.data_product,
        };
        const savedSalesItem = await saveSalesItem({ salesItem: newSalesItem });
        if (savedSalesItem) {
          newSalesItems.push(savedSalesItem);
          salesTmp.data_sales_items = newSalesItems;
          updateTotalValue(salesTmp);
          setSales(salesTmp);
        }
      }
    }
  };

  const addCustomerRedeem = async ({ customerFreeProduct }) => {
    // console.log("2 ==> " + customerFreeProduct);
  };

  return {
    // states
    outstandingInvoices,
    setOutstandingInvoices,
    displays,
    setDisplays,
    sales,
    setSales,
    products,
    setProducts,
    newProducts,
    setNewProducts,
    loading,
    loadingOutstanding,
    loadingProduct,
    // functions
    getSales,
    getOutstandingInvoice,
    getDisplay,
    getProduct,
    reloadListProduct,
    reloadListPenjualan,
    changeSalesItemQuantity,
    addSalesItemByProduct,
    removeSalesItem,
    updateTotalValue,
    saveAndChangeToNewCustomer,
    changeCustomer,
    newSalesProcedure,
    changeSalesItemDiscount,
    changeSalesItemDoctor,
    changeSalesItemNurse,
    changeSalesItemTherapist,
    addPayment,
    removePayment,
    removeDiscount,
    addSalesDiscount,
    onChangeNote,
    voidSales,
    addSalesItemByCustomerPackage,
    addCustomerRedeem,
    scanCheckIn,
    scanCheckOut,
    scanCustomerForNewSales,
    reloadCustomer,
  };
};
export default useSalesPage;
