import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import useAPIRequest from "../../../custom_hooks/simple/useAPIRequest";
import TableAutoComponent from "../../../components/complex/crud/TableAutoComponent";
import { format as dateFnsFormat, format, parse, set } from "date-fns";
import UiContext from "../../../store/ui-context";
import { useDispatch } from "react-redux";
import { setLayoutDashboardTitle } from "../../../store/ui-slice";
import { Paginator } from "primereact/paginator";
import dateTextReformat from "../../../utils/DateTextReformat";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import * as XLSX from "xlsx";

const title = "Customer Package By Product Report";
const defaultTake = 50000;

const ReportCustomerPackageByProduct = () => {
  const uiContext = useContext(UiContext);
  const toast = useRef();
  const dispatch = useDispatch();

  const [paging, setPaging] = useState({
    page: 1,
    take: defaultTake,
    filter: "",
    total: 0,
  });
  const [listData, setListData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
  const { requestGet, loading } = useAPIRequest();

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
    reloadProducts();
  }, []);

  const generateFilter = () => {
    let filter = `type:=:2;expired_date:>=:${dateFnsFormat(
      new Date(),
      "yyyy-MM-dd"
    )};usage_date:=:Null;`;

    if (selectedProduct && selectedProduct.length > 0) {
      const filterProducts = selectedProduct.map(
        (el) => "data_product.id:=:" + el.product_id
      );
      filter += filterProducts.join("||");
    }

    // if (selectedDate) {
    //   if (filter.length > 0) filter += ";";

    //   let dateStart = selectedDate[0];
    //   let dateEnd = selectedDate[1];

    //   if (dateStart)
    //     filter += `transaction_date:>=:${dateFnsFormat(
    //       dateStart,
    //       "yyyy-MM-dd 00:00:00"
    //     )}`;

    //   if (dateEnd)
    //     filter += `;transaction_date:<=:${dateFnsFormat(
    //       dateEnd,
    //       "yyyy-MM-dd 23:59:59"
    //     )}`;
    // }

    return filter;
  };

  const reloadProducts = async () => {
    const params = {
      prefix: "customer_free_product_group_product",
      page: 1,
      take: 10000,
      order: "product_name",
      order_method: "ASC",
      filter: `type:=:2;expired_date:>=:${dateFnsFormat(
        new Date(),
        "yyyy-MM-dd"
      )};usage_date:=:Null;`,
    };

    return requestGet({
      fullUrl: "api/report",
      params,
      onSuccess: ({ pagination, message, data }) => {
        setProducts(data);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const reloadData = async ({ page, take }) => {
    if (loading) return;
    setListData([]);
    setExpandedRows([]);

    const filter = generateFilter();

    const params = {
      prefix: "customer_free_product",
      page: page,
      take: take,
      filter,
      order: "product_name",
      order_method: "ASC",
      with_source_sales_item: 1,
    };

    return requestGet({
      fullUrl: "api/report",
      params,
      onSuccess: ({ pagination, message, data }) => {
        const newList = [];

        const page = pagination["page"];
        const take = pagination["take"];
        const startNumber = (page - 1) * take;

        let no = 1;
        let lastProductName = "";

        data.forEach((item, index) => {
          if (lastProductName !== item.product_name) {
            no = 1;
          }

          newList.push({
            ...item,
            no: no,
            id: index,
          });

          no++;
          lastProductName = item.product_name;
        });

        setPaging({
          ...paging,
          total: pagination["total"],
          page: pagination["page"],
          take: pagination["take"],
        });
        setListData(newList);
      },
      onError: ({ message, data }) => {
        uiContext.showErrorMsg("Error", message);
      },
    });
  };

  const handleReload = () => {
    reloadData({ page: 1, take: defaultTake });
  };

  const handleOnPage = (e) => {
    const newPage = e.first / paging.take + 1;
    const newTake = e.rows;

    setPaging({ ...paging, take: newTake, page: newPage });
    reloadData({ page: newPage, take: newTake });
  };

  const getNestedValue = (obj, key) => {
    return key.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const handleExportToExcel = (data, fileName) => {
    const headers = [
      { name: "Product Name", key: "product_name" },
      { name: "Number", key: "number" },
      { name: "Transaction Date", key: "transaction_date" },
      { name: "Customer Code", key: "customer_code" },
      { name: "Customer Name", key: "customer_name" },
      { name: "Quantity", key: "quantity" },
      { name: "Expired", key: "expired_date" },
      { name: "Price", key: "data_source_sales_item.price" },
      { name: "Package Name", key: "product_package_name" },
    ];

    const numericFields = ["quantity", "data_source_sales_item.price"];

    const formattedData = data.map((row) => {
      const formattedRow = {};
      headers.forEach((header) => {
        const value = getNestedValue(row, header.key); // Use helper to get nested value
        if (numericFields.includes(header.name)) {
            formattedRow[header.name] = value ? Number(value) : 0; // Convert to number for numeric fields
        } else {
            formattedRow[header.name] = value || ""; // Default to empty string if undefined
        }
      });
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    XLSX.utils.sheet_add_aoa(worksheet, [headers.map((header) => header.name)], {
      origin: "A1",
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const calculateProductNameCount = (product_name) => {
    return listData.filter((el) => el.product_name === product_name).length;
  };

  // UI Section
  const headerTemplate = (data) => {
    const { product_name } = data;
    return <React.Fragment>{product_name}</React.Fragment>;
  };

  const footerTemplate = (data) => {
    return (
      <React.Fragment>
        <td colSpan={4}>
          <div className="row-end-end font-bold w-full">
            {`${data.product_name}: ${calculateProductNameCount(
              data.product_name
            )}`}
          </div>
        </td>
      </React.Fragment>
    );
    // return <React.Fragment>This is footer</React.Fragment>;
  };

  const salesBodyTemplate = (row) => {
    const { number, transaction_date } = row;
    return (
      <div className="col-start-start gap-0 ">
        <span className="text-xs font-bold text-slate-400 tracking-wider">
          {transaction_date ? dateTextReformat(transaction_date) : "-"}
        </span>
        <span>{number}</span>
      </div>
    );
  };

  const customerBodyTemplate = (row) => {
    const { customer_code, customer_name } = row;
    return (
      <div className="col-start-start gap-0 ">
        <span className="text-xs font-bold text-slate-400 tracking-wider">
          {customer_code}
        </span>
        <span>{customer_name}</span>
      </div>
    );
  };

  const expiredBodyTemplate = (row) => {
    const { expired_date } = row;
    return expired_date ? format(new Date(expired_date), "dd/MM/yyyy") : "";
  };

  const quantityBodyTemplate = (row) => {
    const { quantity } = row;
    return quantity.toLocaleString("id-ID");
  };

  const priceBodyTemplate = (row) => {
    const { data_source_sales_item } = row;
    const price = data_source_sales_item ? data_source_sales_item.price : 0;
    return data_source_sales_item && data_source_sales_item.price
      ? price.toLocaleString("id-ID")
      : "-";
  };

  const packageNameBodyTemplate = (row) => {
    let { product_package_name } = row;
    if (product_package_name && product_package_name.length > 40) {
      product_package_name = product_package_name.match(/.{1,45}/g).join("\n");
    }
    return <pre className="text-xs">{product_package_name ?? "-"}</pre>;
  };

  return (
    <div className={`col-start-start px-3 gap-4 w-100`}>
      <Toast ref={toast} />
      <div></div>
      {/* <div className="elevated-card-no-padding w-100 row-start-start relative wrap gap-8">
        <div className="row-start-start wrap gap-8 w-100 p-[1rem]"> */}
      {/* field selector selectedDate */}
      {/* <div className={`col-start-start gap-1`}>
            <label
              htmlFor={"selectedDate"}
              className="font-bold text-sm text-zinc-500"
            >
              Transaction Date
            </label>
            <Calendar
              id="selectedDate"
              value={selectedDate}
              showButtonBar
              selectionMode="range"
              // hourFormat="24"
              showIcon
              onChange={(e) => {
                setSelectedDate(e.value);
              }}
              className={`dateInput w-[17rem]`}
              locale="id"
            />
          </div>
        </div>
        {loading && (
          <div
            className={`col-center-center w-100  gap-2 absolute z-100 h-100 bg-black/20 rounded `}
          >
            <ProgressSpinner />
          </div>
        )}
      </div> */}
      <div
        className={`elevated-card-tight-vertical row-between-center w-100 wrap gap-20`}
      >
        <div className="row-between-center grow">
          <MultiSelect
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.value)}
            options={products}
            optionLabel="product_name"
            display="chip"
            placeholder="Select Product"
            maxSelectedLabels={100}
            className="w-full"
          />
        </div>
        <div className="row-end-center gap-2">
          <Button
            icon="pi pi-file-excel"
            size="small"
            loading={loading}
            severity="success"
            // disabled
            onClick={() => handleExportToExcel(listData, "Customer Package by Product Report")}
          />
          <Button
            icon="pi pi-print"
            size="small"
            loading={loading}
            severity="success"
            disabled
          />
          <Button
            icon="pi pi-refresh"
            size="small"
            loading={loading}
            onClick={() => handleReload()}
            // rounded
            outlined
          />
        </div>
      </div>

      <div className={`elevated-card row-start-start w-100  wrap gap-4`}>
        <div className="col-start-center relative w-100 h-100 gap-3">
          <DataTable
            className="w-full"
            value={listData}
            rowGroupMode="subheader"
            groupRowsBy="product_name"
            expandableRowGroups
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowGroupHeaderTemplate={headerTemplate}
            rowGroupFooterTemplate={footerTemplate}
            showGridlines
            stripedRows
            cellClassName="!py-2"
            // sortMode="single"
            // sortField="birthday"
          >
            <Column field="no" header="No" />
            <Column field="sales" header="Sales" body={salesBodyTemplate} />
            <Column
              field="code"
              header="Customer"
              body={customerBodyTemplate}
            />
            <Column
              field="quantity"
              header="Qty"
              align={"right"}
              body={quantityBodyTemplate}
            />
            <Column
              field="expired"
              header="Expired"
              body={expiredBodyTemplate}
            />
            <Column
              field="price"
              header="@ Price"
              align={"right"}
              body={priceBodyTemplate}
            />
            <Column
              field="product_package_name"
              header="Package Name"
              body={packageNameBodyTemplate}
            />
          </DataTable>
          {/* <TableAutoComponent
            onSelectionChange={() => {}}
            configs={tableConfigs}
            loading={loading}
            value={listData}
            onEditRow={async (row) => {}}
            onDeleteRow={(row) => {}}
            customColumns={customColumns}
          /> */}
          <Paginator
            first={paging.take * (paging.page - 1)}
            rows={paging.take}
            totalRecords={paging.total}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onPageChange={handleOnPage}
          />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default ReportCustomerPackageByProduct;
