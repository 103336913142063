// import logo from "./logo.svg";
import "./App.css";

import idLocale from "./assets/locale/id.json";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { addLocale } from "primereact/api";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useContext, Suspense } from "react";
import UiContext from "./store/ui-context";

import ErrorPage from "./pages/error/ErrorPage";
import LoginPage from "./pages/login/LoginPage";

import LayoutDashboardPage from "./pages/dashboard/LayoutDashboardPage";
import LayoutDashboardUserPage from "./pages/dashboard-user/LayoutDashboardUserPage";

//import protector
import ProtectedRoute from "./pages/protected/ProtectedRoute";
import ProtectedRouteUser from "./pages/protected/ProtectedRouteUser";

//import admin page
import HomePage from "./pages/dashboard/default/HomePage";
import SignUpPage from "./pages/sign-up/SignUpPage";

//import user page
import UserHomePage from "./pages/dashboard-user/default/UserHomePage";
import ProductCategoryPage from "./pages/dashboard/master/productcategory/ProductCategoryPage";
import BranchPage from "./pages/dashboard/master/branch/BranchPage";
import WarehousePage from "./pages/dashboard/master/warehouse/WarehousePage";
import ProductDisplayPage from "./pages/dashboard/master/productdisplay/ProductDisplayPage";
import ProductPage from "./pages/dashboard/master/product/ProductPage";
import PaymentPage from "./pages/dashboard/master/payment/PaymentPage";
import VoucherPage from "./pages/dashboard/master/voucher/VoucherPage";
import ExpenseGroupPage from "./pages/dashboard/master/expensegroup/ExpenseGroupPage";
import ExpensePage from "./pages/dashboard/master/expense/ExpensePage";
import TargetPage from "./pages/dashboard/master/target/TargetPage";
import CRMBannerPage from "./pages/dashboard/CRM/crmbanner/CRMBannerPage";
import CRMNewsPage from "./pages/dashboard/CRM/crmnews/CRMNewsPage";
import CRMLevelPage from "./pages/dashboard/CRM/crmlevel/CRMLevelPage";
import CRMProductCategoryPage from "./pages/dashboard/CRM/crmproductcategory/CRMProductCategoryPage";
import CRMProductPage from "./pages/dashboard/CRM/crmproduct/CRMProductPage";
import SalesPage from "./pages/dashboard/transaksi/sales/SalesPage";
import DiscountPage from "./pages/dashboard/master/discount/DiscountPage";
import EmployeePage from "./pages/dashboard/master/employee/EmployeePage";
import CustomerPage from "./pages/dashboard/master/customer/CustomerPage";
import SalesJournalPage from "./pages/dashboard/transaksi/salesjournal/SalesJournalPage";
import ReportMainPage from "./pages/report/ReportMainPage";
import AppointmentPage from "./pages/dashboard/transaksi/appointment/AppointmentPage";
import CustomerDataPage from "./pages/dashboard/transaksi/customerdata/CustomerDataPage";
import StockInPage from "./pages/dashboard/Stock/stock-in/StockInArsipPage";
import StockOutPage from "./pages/dashboard/Stock/stock-out/StockOutArsipPage";
import StockBalancePage from "./pages/dashboard/Stock/stock-balance/StockBalancePage";
import StockMovementPage from "./pages/dashboard/Stock/stock-movement/StockMovementPage";
import StockTransferPage from "./pages/dashboard/Stock/stock-transfer/StockTransferArsipPage";
import StockInArsipPage from "./pages/dashboard/Stock/stock-in/StockInArsipPage";
import StockInEditorPage from "./pages/dashboard/Stock/stock-in/stock-in-editor/StockInEditorPage";
import StockOutArsipPage from "./pages/dashboard/Stock/stock-out/StockOutArsipPage";
import StockOutEditorPage from "./pages/dashboard/Stock/stock-out/stock-out-editor/StockOutEditorPage";
import StockTransferArsipPage from "./pages/dashboard/Stock/stock-transfer/StockTransferArsipPage";
import StockTransferEditorPage from "./pages/dashboard/Stock/stock-transfer/stock-transfer-editor/StockTransferEditorPage";
import BirthdayPage from "./pages/dashboard/transaksi/birthday/BirthdayPage";
import SettingPage from "./pages/dashboard/setting/general/SettingPage";
import CustomerFollowUpPage from "./pages/dashboard/transaksi/customer-follow-up/CustomerFollowUpPage";
import PriceListPage from "./pages/dashboard/transaksi/price-list/PriceListPage";
import ReportSalesDetail from "./pages/report/reportsales/ReportSalesDetail";
import ReportCustomerPackageByProduct from "./pages/report/reportsales/ReportCustomerPackageByProduct";
import ReportCustomerSales from "./pages/report/reportsales/ReportCustomerSales";

// import reports

function App() {
  const uiContext = useContext(UiContext);

  const toastRef = useRef();

  useEffect(() => {
    uiContext.setToastRef(toastRef);
  }, [toastRef]);

  addLocale("id", idLocale.id);

  const MyRouter = (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/sign-up" element={<SignUpPage />}></Route>

        <Route
          // index
          path="/"
          element={
            <ProtectedRoute>
              <LayoutDashboardPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<HomePage />}></Route>

          <Route path="transaction">
            <Route path="sales" element={<SalesPage />} />
            <Route path="salesjournal" element={<SalesJournalPage />} />
            <Route path="appointment" element={<AppointmentPage />} />
            <Route path="birthday" element={<BirthdayPage />} />
            <Route path="follow-up" element={<CustomerFollowUpPage />} />
            <Route path="price-list" element={<PriceListPage />} />
            <Route path="customerdata">
              <Route index element={<CustomerDataPage />} />
              <Route path=":id" element={<CustomerDataPage />} />
            </Route>
          </Route>
          <Route path="stock">
            <Route path="stockin">
              <Route index element={<StockInArsipPage />} />
              <Route path=":id" element={<StockInEditorPage />} />
            </Route>
            <Route path="stockout">
              <Route index element={<StockOutArsipPage />} />
              <Route path=":id" element={<StockOutEditorPage />} />
            </Route>
            <Route path="stocktransfer">
              <Route index element={<StockTransferArsipPage />} />
              <Route path=":id" element={<StockTransferEditorPage />} />
            </Route>
            <Route path="stockbalance" element={<StockBalancePage />} />
            <Route path="stockmovement" element={<StockMovementPage />} />
          </Route>
          <Route path="report">
            <Route index element={<ReportMainPage />} />
            <Route path="detail-sales-report" element={<ReportSalesDetail />} />
            <Route
              path="customer-package-by-product-report"
              element={<ReportCustomerPackageByProduct />}
            />
            <Route
              path="customer-sales-report"
              element={<ReportCustomerSales />}
            />
          </Route>
          <Route path="master">
            <Route path="branch" element={<BranchPage />} />
            <Route path="warehouse" element={<WarehousePage />} />
            <Route path="productcategory" element={<ProductCategoryPage />} />
            <Route path="productdisplay" element={<ProductDisplayPage />} />
            <Route path="product" element={<ProductPage />} />
            <Route path="payment" element={<PaymentPage />} />
            <Route path="voucher" element={<VoucherPage />} />
            <Route path="expensegroup" element={<ExpenseGroupPage />} />
            <Route path="expense" element={<ExpensePage />} />
            <Route path="target" element={<TargetPage />} />
            <Route path="discount" element={<DiscountPage />} />
            <Route path="employee" element={<EmployeePage />} />
            <Route path="customer" element={<CustomerPage />} />
          </Route>
          <Route path="crm">
            <Route path="crmbanner" element={<CRMBannerPage />} />
            <Route path="crmnews" element={<CRMNewsPage />} />
            <Route path="crmlevel" element={<CRMLevelPage />} />
            <Route
              path="crmproductcategory"
              element={<CRMProductCategoryPage />}
            />
            <Route path="crmproduct" element={<CRMProductPage />} />
          </Route>
          <Route path="setting">
            <Route path="general" element={<SettingPage />} />
          </Route>
        </Route>

        <Route
          // index
          path="user"
          element={
            <ProtectedRouteUser>
              <LayoutDashboardUserPage />
            </ProtectedRouteUser>
          }
        >
          <Route path="main" element={<UserHomePage />}></Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );

  return (
    <>
      <Toast ref={toastRef} />
      {!toastRef.current && (
        <div className="col-center-center py-4">
          <h1> Initializing application ... </h1>{" "}
        </div>
      )}
      {toastRef.current && MyRouter}
    </>
  );
}

export default App;
