import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutDashboardTitle } from "../../store/ui-slice";
import ReportSection from "./ReportSection";
import ReportDailySales from "./reportsales/ReportDailySales";
import checkPermission from "../../utils/CheckPermission";
import {
  isBC,
  isOwner,
  isSuperAdmin,
  isFinance,
  isCashier,
} from "../../utils/jelim/CheckUserType";
import { is } from "date-fns/locale";

const title = "Laporan";

const ReportMainPage = () => {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.auth.employee);
  const userType = employee ? employee.user_type : -1;
  // checkPermission("view report");

  const [showReportDailySales, setShowReportDailySales] = useState(false);

  var dataSalesDetails = [];

  if ( isOwner(userType) || isSuperAdmin(userType) || isFinance(userType) || isCashier(userType) ) {
    dataSalesDetails.push({
      id: "0-1",
      title: "Summary Sales Report (POS)",
      description: "Summarize daily sales activity",
      url: "/report/zzzz",
      on_click: (e) => setShowReportDailySales(true),
    });
  }

  if ( isOwner(userType) || isSuperAdmin(userType) || isFinance(userType) ) {
    dataSalesDetails.push({
      id: "0-2",
      title: "Detail Sales Report",
      description: "Detail sales, sorted by sales number and date",
      url: "/report/detail-sales-report",
    });
  }

  var dataSales = {
    id: "0",
    title: "Sales",
    details: dataSalesDetails,
    // details: [
    //   {
    //     id: "0-1",
    //     title: "Summary Sales Report (POS)",
    //     description: "Summarize daily sales activity",
    //     url: "/report/zzzz",
    //     on_click: (e) => setShowReportDailySales(true),
    //     // class_color: "text-red-300",
    //   },
    //   {
    //     id: "0-2",
    //     title: "Detail Sales Report",
    //     description: "Detail sales, sorted by sales number and date",
    //     url: "/report/detail-sales-report",
    //     // on_click: (e) => setShowReportDailySales(true),
    //     // class_color: "text-red-300",
    //   },
    // ],
  };

  var dataCustomerDetails = [];

  if ( isOwner(userType) || isSuperAdmin(userType) || isFinance(userType) ) {
    dataCustomerDetails.push({
      id: "1-1",
      title: "Customer Package By Product",
      description: "Customer package data grouped by product name",
      url: "/report/customer-package-by-product-report",
    });
  }

  if ( isOwner(userType) || isSuperAdmin(userType) || isFinance(userType) || isBC(userType) ) {
    dataCustomerDetails.push({
      id: "1-2",
      title: "Customer Sales Report",
      description: "Sales report data grouped by customer",
      url: "/report/customer-sales-report",
    });
  }

  var dataCustomer = {
    id: "1",
    title: "Customer",
    details: dataCustomerDetails,
    // details: [
    //   {
    //     id: "1-1",
    //     title: "Customer Package By Product",
    //     description: "Customer package data grouped by product name",
    //     url: "/report/customer-package-by-product-report",
    //   },
    //   {
    //     id: "1-2",
    //     title: "Customer Sales Report",
    //     description: "Sales report data grouped by customer",
    //     url: "/report/customer-sales-report",
    //   },
    // ],
  };

  useEffect(() => {
    dispatch(setLayoutDashboardTitle({ title }));
  }, []);

  return (
    <section className="row-start-start w-100 wrap p-4 gap-4">
      <ReportDailySales
        visible={showReportDailySales}
        onHide={(e) => setShowReportDailySales(false)}
      />

      <div className="col-start-start gap-5 grow">
        {dataSalesDetails.length > 0 ? (
          <ReportSection {...dataSales} key={dataSales.id} />
        ) : null}
        {dataCustomerDetails.length > 0 ? (
          <ReportSection {...dataCustomer} key={dataCustomer.id} />
        ) : null}
      </div>
      {/* <div className="col-start-start gap-5 grow"></div> */}
    </section>
  );
};

export default ReportMainPage;
