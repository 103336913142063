import {
  FaArrowRightArrowLeft,
  FaBook,
  FaCakeCandles,
  FaCalendarCheck,
  FaChartBar,
  FaChartSimple,
  FaCircleLeft,
  FaCircleRight,
  FaDollarSign,
  FaFaceSmile,
  FaHouse,
  FaReceipt,
  FaSquareCheck,
} from "react-icons/fa6";

const MenuBC = [
  {
    label: "Home",
    to: "",
    icon: <FaHouse />,
  },
  {
    label: "Transaction",
    type: "menu-title",
  },
  {
    label: "Appointment",
    to: "transaction/appointment",
    icon: <FaCalendarCheck />,
  },
  {
    label: "Customer Data",
    to: "transaction/customerdata",
    icon: <FaFaceSmile />,
  },
  {
    label: "Price List",
    to: "transaction/price-list",
    icon: <FaDollarSign />,
  },
  {
    label: "Birthday",
    to: "transaction/birthday",
    icon: <FaCakeCandles />,
  },
  {
    label: "Follow Up",
    to: "transaction/follow-up",
    icon: <FaSquareCheck />,
  },
  {
    label: "Report",
    type: "menu-title",
  },
  {
    label: "Report",
    to: "report",
    icon: <FaChartBar />,
  },
  {
    label: "Utilities",
    type: "menu-title",
  },
];

export default MenuBC;
